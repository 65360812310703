import {
  formatDate,
  formatISO,
  getKoreanTime,
  ignoreTimezone,
  parseISO,
} from "@enkor/date-fns";

import { EventInfo, EventInfoCategoryInfo } from "@models/data-contracts";

class EventInfoViewModel {
  id: number;
  location: {
    areaName: string;
    nearStation: string;
    spotName: string;
  };
  eventImage: string;
  title: string;
  date: string;
  time: string;
  isFull: boolean;
  isClosed: boolean;
  participantCount: number;
  maxParticipantCount: number;
  host: {
    name: string;
    profileImage?: string;
  };
  participantList?: { profileImage: string }[];
  categoryList: EventInfoCategoryInfo[];

  static create(responseData: EventInfo) {
    const event = new EventInfoViewModel();

    event.id = responseData.id;

    // HACK: UTC(000Z) 기준 timezone만 변경하여 한국시간으로 변경해서 사용. (모든 나라에서 한국 시간 기준으로 디스플레이)
    event.date = formatDate({
      date: formatISO(
        getKoreanTime(parseISO(ignoreTimezone(responseData.startDate))),
      ),
      type: "ENG_MONTH_DAY_YEAR",
    });

    event.time = formatDate({
      date: formatISO(
        getKoreanTime(parseISO(ignoreTimezone(responseData.startDate))),
      ),
      customType: "aa hh:mm",
    });

    event.title = responseData?.title;
    event.eventImage = responseData.eventImageUrl;
    event.location = {
      areaName: responseData?.location.address,
      nearStation: responseData.location.nearLocation
        ? `${responseData.location.nearLocation} ${
            responseData.location.nearLocation.includes("Station")
              ? ""
              : "Station"
          }`
        : responseData?.location.address,
      spotName: responseData?.location.address || "",
    };

    event.isFull = responseData?.isFull === "Y";
    event.isClosed = responseData?.isClosed === "Y";
    event.participantCount = responseData?.memberList.length;
    event.maxParticipantCount = responseData.maxPeople;

    event.host = {
      name: responseData.hostName,
      profileImage: responseData.hostProfileUrl,
    };

    event.participantList = responseData.memberList.map((member) => ({
      profileImage: member.url,
    }));

    event.categoryList = responseData.categoryList;

    return event;
  }
}

export default EventInfoViewModel;
