const EVENT_AUTH = {
  CLICK_LOGIN_OR_SIGNUP: "click login_or_signup",
  CLICK_EMAIL: "click check_email",
  CLICK_SOCIAL_LOGIN: "click social_login",
  CLICK_LOGOUT: "click auth.logout",

  VIEW_ENTRY: "view auth.entry",
  VIEW_LOGIN: "view auth.login",
  VIEW_SIGNUP: "view auth.signup",
  VIEW_SIGNUP_COMPLETE: "view auth.signup_complete",

  TRIGGER_EMAIL_LOGIN: "trigger email_login",
};

const EVENT_PROFILE = {
  CLICK_NEW_PROFILE: "trigger profile.new_profile_create",
  CLICK_EDIT_PROFILE: "trigger profile.profile_edit",
  CLICK_HOST_PROFILE: "click host_profile",
};

const EVENT_EVENT_LIST = {
  CLICK_EVENT_CARD: "click event_list.event_card",
  CLICK_FULL_EVENT_CARD: "click event_list.full_event_card",
  CLICK_CLOSED_EVENT_CARD: "click event_list.closed_event_card",
  CLICK_FEATURED_EVENT_CARD: "click event_list.featured_event_card",
  CLICK_VIEW_MORE: "click event_list.view_more",

  VIEW_EVENT_LIST: "view event_list",
};

const EVENT_HOST_EVENT = {
  CLICK_START_EVENT_CREATE: "click event.host.start_create",
  CLICK_PROFILE_START_EVENT_CREATE: "click event.profilehost.start_create",
  CLICK_FINISH_EVENT_CREATE: "click event.host.finish_create",
  CLICK_START_EVENT_DELETE: "click event.host.start_delete",
  CLICK_FINISH_EVENT_DELETE: "click event.host.finish_delete",
};

const EVENT_GUEST_EVENT = {
  CLICK_JOIN: "click event.guest.join",
  CLICK_SHARE: "click event.guest.share",
  CLICK_START_EVENT_LEAVE: "click event.guest.start_leave",
  CLICK_FINISH_EVENT_LEAVE: "click event.guest.finish_leave",
  CLICK_JOINER_PROFILE: "click event.joiner_profile",
  CLICK_EDITION_INFO: "click event.edition_info",
  CLICK_EDITION_CTA: "click event.edition_cta",
};

const EVENT_REVIEW = {
  CLICK_START_REVIEW: "click review.start_create",
  CLICK_FINISH_REVIEW: "click review.finish_create",
};

const EVENT_CHAT = {
  CLICK_CHATROOM_LIST: "click chat.chatroom_list",
  CLICK_CHATROOM_CARD: "click chat.chatroom_card",

  VIEW_CHATROOM_LIST: "view chat.chatroom_list",
  VIEW_CHATROOM: "view chat.chatroom",
};

const EVENT_MODAL_POPUP = {
  VOTE_CLICK: "modalpop.user.vote",
  VOTE_CLOSE: "modalpop.user.vote_close",
};

const EVENT_LANDING = {
  APP_DOWNLOAD: "click appdownload_btn",
  HERO_CTA_GOOGLE_PLAY: "click HeroCTA-googlePlay",
  HERO_CTA_APP_STORE: "click HeroCTA-appStore",
  PLATFORM_CTA_EDITOR_PICK: "click PlatformCTA-editorPick",
  END_CTA_GOOGLE_PLAY: "click EndCTA-googlePlay",
  END_CTA_APP_STORE: "click EndCTA-appStore",
};

const EVENT_LANDING_DETAIL = {
  DETAIL_HOST_PROFILE: "click Detail-hostProfile",
  DETAIL_PARTICIPANT_LIST: "click Detail-participantList",
  DETAIL_CTA: "click Detail-CTA",
};

const EVENT_PROJECT_DETAIL = {
  CLICK_EVENT_CARD: "click edition.event_card",
  CLICK_FULL_EVENT_CARD: "click edition.full_event_card",
  CLICK_CLOSED_EVENT_CARD: "click edition.closed_event_card",
  CLICK_MEMBER_PROFILE: "click edition.member_profile",
  CLICK_PAYMENT_JOIN: "click edition.payment_join",
  TRIGGER_PAYMENT_COMPLETE: "trigger edition.payment_complete",
};

const EVENT_MY_PAGE = {
  CLICK_PROJECT_TAB: "click mypage.edition_tab",
  CLICK_PROJECT_CARD: "click mypage.edition_card",
  CLICK_PROJECT_EVENT_CARD: "click mypage.edition_event_card",
  CLICK_PROJECT_DETAIL: "click mypage.edition_detail",
};

export const DATA_TRACKING_EVENT_NAME = {
  AUTH: EVENT_AUTH,
  PROFILE: EVENT_PROFILE,
  EVENT_LIST: EVENT_EVENT_LIST,
  HOST_EVENT: EVENT_HOST_EVENT,
  GUEST_EVENT: EVENT_GUEST_EVENT,
  MY_PAGE: EVENT_MY_PAGE,
  REVIEW: EVENT_REVIEW,
  CHAT: EVENT_CHAT,
  MODAL_POPUP: EVENT_MODAL_POPUP,
  LANDING: EVENT_LANDING,
  LANDING_DETAIL: EVENT_LANDING_DETAIL,
  PROJECT_DETAIL: EVENT_PROJECT_DETAIL,
};
