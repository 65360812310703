import { useState, useEffect } from "react";

export interface WindowSizeType {
  width: number | undefined;
  height: number | undefined;
}

/**
 * @description 윈도우 사이즈를 가져오는 hook
 *
 * useWindowSize를 사용하는 컴포넌트에서도 가능하면 이 hook 보다 css로 처리할 수 있는 방법을 먼저 고려해야 함.
 *
 * @example
 * ```typescript
 * const { width, height } = useWindowSize();
 * ```
 */

export default function useWindowSize(): WindowSizeType {
  const [windowSize, setWindowSize] = useState<WindowSizeType>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
