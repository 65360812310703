import {
  LEGAL_DOCUMENT_LINKS,
  ARC_LEGAL_DOCUMENT_LINKS,
  LETSFIP_LEGAL_DOCUMENT_LINKS,
} from "../externalLinks";

type DocumentType = keyof typeof LEGAL_DOCUMENT_LINKS;
/**
 * 주어진 문서 유형과 로케일에 따라 적절한 정책 문서 링크를 반환합니다.
 *
 * @param docType - 정책 문서의 유형으로, `LEGAL_DOCUMENT_LINKS`의 키여야 합니다.
 * @param locale - 로케일 문자열로, 해당 로케일에 맞는 문서 링크를 선택합니다.
 * @returns 주어진 로케일에 맞는 정책 문서 링크를 반환합니다. 로케일이 없을 경우 기본적으로 영어(`en`) 버전을 반환합니다.
 *
 * @example
 * ```ts
 * // useRouter를 사용하지 않는 경우
 * import { getLegalDocumentLink } from './documentLinks';
 *
 * const privacyPolicyLink = getLegalDocumentLink('PRIVACY_POLICY', 'ko');
 * console.log(privacyPolicyLink); // 한국어 버전의 개인정보 보호정책 링크를 반환합니다. 해당 로케일이 없으면 영어 버전을 반환합니다.
 *
 * // useRouter를 사용하는 경우
 * import { getLegalDocumentLink } from './documentLinks';
 * import { useRouter } from 'next/router';
 *
 * const { locale } = useRouter();
 * const termsLink = getLegalDocumentLink('TERMS_OF_CONDITIONS', locale);
 * console.log(termsLink); // 현재 로케일에 맞는 서비스 약관 링크를 반환합니다.
 * ```
 */
export const getLegalDocumentLink = (
  docType: DocumentType,
  locale: string | undefined,
): string => {
  const document = LEGAL_DOCUMENT_LINKS[docType];

  return document[locale as keyof typeof document] || document.en;
};

// ---------------------------------------------------------------------------------------------

type ARCDocumentType = keyof typeof ARC_LEGAL_DOCUMENT_LINKS;

/**
 * 주어진 ARC 문서 유형과 로케일에 따라 적절한 ARC 정책 문서 링크를 반환합니다.
 *
 * @param docType - ARC 정책 문서 유형으로, `ARC_LEGAL_DOCUMENT_LINKS`의 키여야 합니다.
 * @param locale - 로케일 문자열로, 해당 로케일에 맞는 ARC 문서 링크를 선택합니다.
 * @returns 주어진 로케일에 맞는 ARC 정책 문서 링크를 반환합니다. 로케일이 없을 경우 기본적으로 영어(`en`) 버전을 반환합니다.
 *
 * @example
 * ```ts
 * // useRouter를 사용하지 않는 경우
 * import { getARCLegalDocumentLink } from './documentLinks';
 *
 * const arcPolicyLink = getARCLegalDocumentLink('POLICY', 'ko');
 * console.log(arcPolicyLink); // 한국어 버전의 ARC 정책 링크를 반환합니다. 해당 로케일이 없으면 영어 버전을 반환합니다.
 *
 * // useRouter를 사용하는 경우
 * import { getARCLegalDocumentLink } from './documentLinks';
 * import { useRouter } from 'next/router';
 *
 * const { locale } = useRouter();
 * const arcLink = getARCLegalDocumentLink('POLICY', locale);
 * console.log(arcLink); // 현재 로케일에 맞는 ARC 정책 링크를 반환합니다.
 * ```
 */
export const getARCLegalDocumentLink = (
  docType: ARCDocumentType,
  locale: string | undefined,
): string => {
  const document = ARC_LEGAL_DOCUMENT_LINKS[docType];
  return document[locale as keyof typeof document] || document.en;
};

// ---------------------------------------------------------------------------------------------

type LetsfipDocumentType = keyof typeof LETSFIP_LEGAL_DOCUMENT_LINKS;

/**
 * 주어진 Letsfip 문서 유형과 로케일에 따라 적절한 Letsfip 정책 문서 링크를 반환합니다.
 *
 * @param docType - Letsfip 정책 문서 유형으로, `LETSFIP_LEGAL_DOCUMENT_LINKS`의 키여야 합니다.
 * @param locale - 로케일 문자열로, 해당 로케일에 맞는 Letsfip 문서 링크를 선택합니다.
 * @returns 주어진 로케일에 맞는 Letsfip 정책 문서 링크를 반환합니다. 로케일이 없을 경우 기본적으로 영어(`en`) 버전을 반환합니다.
 *
 * @example
 * ```ts
 * // useRouter를 사용하지 않는 경우
 * import { getLetsfipLegalDocumentLink } from './documentLinks';
 *
 * const letsfipPolicyLink = getLetsfipLegalDocumentLink('PRIVACY_POLICY', 'ko');
 * console.log(letsfipPolicyLink); // 한국어 버전의 Letsfip 정책 링크를 반환합니다. 해당 로케일이 없으면 영어 버전을 반환합니다.
 *
 * // useRouter를 사용하는 경우
 * import { getLetsfipLegalDocumentLink } from './documentLinks';
 * import { useRouter } from 'next/router';
 *
 * const { locale } = useRouter();
 * const letsfipLink = getLetsfipLegalDocumentLink('TERMS_OF_CONDITIONS', locale);
 * console.log(letsfipLink); // 현재 로케일에 맞는 Letsfip 정책 링크를 반환합니다.
 * ```
 */

export const getLetsfipLegalDocumentLink = (
  docType: LetsfipDocumentType,
  locale: string | undefined,
): string => {
  const document = LETSFIP_LEGAL_DOCUMENT_LINKS[docType];
  return document[locale as keyof typeof document] || document.en;
};
