import Slider, { Settings } from "react-slick";

import { SwitchCase } from "@enkor/toss-slash";

import FeaturedEventCardSkeleton from "@domains/common/views/placeholders/FeaturedEventCardSkeleton/FeaturedEventCardSkeleton";
import { useFetchEventListInfiniteQuery } from "@domains/eventList/network/eventListQueries";

import EventCard from "./EventCard/EventCard";

import * as S from "./OngoingSection.style";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderSettings: Settings = {
  dots: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: false,
  arrows: false,
  speed: 250,
  touchThreshold: 100,
  cssEase: "linear",
  appendDots: (dots) => (
    <S.CustomSlickDots>
      <S.DotsList> {dots} </S.DotsList>
    </S.CustomSlickDots>
  ),
};

const OngoingSection = () => {
  const { data: events, status } = useFetchEventListInfiniteQuery({
    limit: 15,
  });

  return (
    <S.Container>
      <SwitchCase
        value={status}
        caseBy={{
          pending: <FeaturedEventCardSkeleton />,
          success: (
            <Slider
              {...sliderSettings}
              infinite={(events?.list.length || 0) > 1}
            >
              {events?.list.map((event) => (
                <EventCard
                  key={`event-${event.id}`}
                  eventId={event.id}
                  eventInfo={event}
                  host={event.host}
                />
              ))}
            </Slider>
          ),
        }}
      />
    </S.Container>
  );
};

export default OngoingSection;
