import { GetServerSidePropsContext } from "next";

import Landing from "@domains/intro/views/Landing/Landing";
import GeneralHead from "@logics/tags/GeneralHead";
import { loadLocaleProps } from "@logics/utils/i18n";

const Home = () => {
  return (
    <>
      <GeneralHead />

      <Landing />
    </>
  );
};

export default Home;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return {
    props: {
      ...(await loadLocaleProps(context)),
    },
  };
}
