import { UserViewModel } from "@domains/auth/viewModels/UserViewModel";

import { AmplitudeTracking } from "./amplitude";

export interface AnalyticsEvent<T> {
  eventName: string;
  properties?: T;
}

export const identifyUser = (user: UserViewModel) => {
  AmplitudeTracking.getInstance().identifyUser<UserViewModel>(user);

  if (window.gtag) {
    window.gtag("set", {
      user_id: user?.id,
      user_properties: {
        auth_provider: user?.authProvider,
      },
    });
  }
};

export const dataTrackEvent = <T extends object>(event: AnalyticsEvent<T>) => {
  const { eventName, properties } = event;

  AmplitudeTracking.getInstance().trackEvent<T>(eventName, properties);

  if (window.gtag) {
    window.gtag("event", eventName, properties);
  }
};
