import * as S from "./FeaturedEventCardSkeleton.style";

const FeaturedEventCardSkeleton = () => {
  return (
    <S.Container>
      <S.EventCardListWrapper>
        {[0, 1].map((el) => (
          <S.EventCard key={el}>
            <S.FlexBox>
              <S.LabelSkeleton />
            </S.FlexBox>

            <S.FlexBox>
              <S.LongContent />
              <S.ShortContent />
            </S.FlexBox>
          </S.EventCard>
        ))}
      </S.EventCardListWrapper>
    </S.Container>
  );
};

export default FeaturedEventCardSkeleton;
