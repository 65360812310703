import styled from "styled-components";

import { getOpacityColor, grayScale, pink } from "@enkor/design/colors";
import { mediaQueries } from "@enkor/design/mixins";

export const Container = styled.div`
  width: 100%;
  max-width: 1018px;

  ${mediaQueries.laptop} {
    width: 768px;
    margin: 0 auto;
  }
`;

export const CustomSlickDots = styled.div`
  bottom: -35px;

  ${mediaQueries.laptop} {
    left: -24px;
  }

  ${mediaQueries.pad} {
    width: 100vw;
    left: -16px;
  }
`;

export const DotsList = styled.ul`
  margin: 0;
  margin-bottom: -4px;

  li {
    width: 18px;
    margin: 0;

    button {
      padding: 0;

      transition: all 0.3s ease-in;

      &::before {
        left: 50%;

        color: ${getOpacityColor(grayScale.white, 0.4)};
        font-size: 10px;
        text-align: center;

        opacity: 1;

        transform: translateX(-50%);
      }
    }
  }

  li.slick-active button:before {
    color: ${pink.main};

    opacity: 1;
  }

  li button:hover::before {
    color: ${pink.main};

    opacity: 0.75;
  }
`;
