import { useRouter } from "next/router";
import { MouseEventHandler } from "react";

import { formatDate } from "@enkor/date-fns";
import { useBooleanState } from "@enkor/toss-slash";

import { NAVIGATION_ROUTE } from "@domains/common/constants/navigationRoute";
import EventViewModel from "@domains/eventList/viewModel/EventViewModel";
import { dataTrackEvent } from "@logics/utils/dataTrackEvent";
import { DATA_TRACKING_EVENT_NAME } from "@domains/common/constants/dataTrackEventName";

import * as S from "./EventCard.style";

interface EventCardProps {
  eventId: number;
  host: EventViewModel["host"];
  eventInfo: EventViewModel;
}

const EventCard = ({ eventId, host, eventInfo }: EventCardProps) => {
  const router = useRouter();

  const [isMouseMoving, setIsMouseMoving, unsetIsMouseMoving] =
    useBooleanState();

  const seatsLeft = eventInfo.maxParticipantCount - eventInfo.participantCount;

  const handleClickCard: MouseEventHandler<HTMLDivElement> = () => {
    if (isMouseMoving) return;

    dataTrackEvent({
      eventName: DATA_TRACKING_EVENT_NAME.LANDING.PLATFORM_CTA_EDITOR_PICK,
      properties: {
        evenId: eventInfo.id,
        eventCategory: eventInfo.categoryList[0].name,
        eventTitle: eventInfo.title,
        eventImage: eventInfo.eventImage,
        eventDate: eventInfo.date,
        eventMaxParticipantCount: eventInfo.maxParticipantCount,
        eventParticipantCount: eventInfo.participantCount,
        host,
      },
    });

    router.push({
      pathname: NAVIGATION_ROUTE.LANDING_DETAIL.PATHNAME,
      query: { ...router.query, eventId },
    });
  };

  return (
    <S.EventCard
      draggable={false}
      onMouseMove={() => setIsMouseMoving()}
      onMouseDown={() => unsetIsMouseMoving()}
      onMouseUp={handleClickCard}
    >
      <S.EventBackgroundImage
        priority
        fill
        src={
          (eventInfo.eventImage?.startsWith("https://")
            ? eventInfo.eventImage
            : "") || "/assets/park-road.png"
        }
        alt="Event-Background"
        width={0}
        height={0}
        sizes={`${S.MAX_CARD_WIDTH}px`}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />

      <S.EventInfoWrapper>
        <S.EventDetailWrapper>
          <S.EventDetailBox>
            <S.EventDetail name="strongM">
              {formatDate({
                date: eventInfo.date,
                customType: "MMM d",
              })}
            </S.EventDetail>
            <S.EventDetail name="s">{eventInfo.time}</S.EventDetail>
          </S.EventDetailBox>

          {seatsLeft <= 5 && (
            <S.LabelText name="strongS">{seatsLeft} Seats left!</S.LabelText>
          )}
        </S.EventDetailWrapper>

        <S.EventInfo>
          <S.EventTitle name="l">{eventInfo.title}</S.EventTitle>
          <S.HostInfo>
            <S.AvatarImageWrapper $withBorder>
              <S.HostAvatar
                src={host.profileImage || "/assets/image_default9.png"}
                alt="Host profile"
                width={0}
                height={0}
                quality={50}
                sizes={`${S.AVATAR_SIZE}px`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </S.AvatarImageWrapper>
            <S.HostName name="strongM">Hosted by {host.name}</S.HostName>
          </S.HostInfo>
        </S.EventInfo>
      </S.EventInfoWrapper>

      <S.BlurOverlay />
    </S.EventCard>
  );
};

export default EventCard;
