import { isValid } from "date-fns";

const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

const localToday = new Date();

const utc = localToday.getTime() + localToday.getTimezoneOffset() * 60 * 1000;

const koreanToday = new Date(utc + KR_TIME_DIFF);

function getKoreanTime(date: Date) {
  const utc = date.getTime() + date.getTimezoneOffset() * 60 * 1000;

  return new Date(utc + KR_TIME_DIFF);
}

/**
 * 주어진 Date 객체 또는 날짜 문자열의 로컬 시간을 유지하고, 타임존을 KST(+09:00)로 설정하여 반환하는 함수
 *
 * @param Date - `{Date|string}` inputDate - 변환할 날짜와 시간이 담긴 Date 객체 또는 날짜 문자열
 *
 * @returns string - "2024-03-17T19:00:00+09:00" 형식으로 조정된 날짜와 시간 문자열
 */
function formatToKSTDateString(inputDate: Date | string) {
  const date = new Date(inputDate);

  // 날짜와 시간 구성 요소 추출 (로컬 시간 기준)
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth()는 0부터 시작하므로 1을 더합니다.
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  // KST 타임존 문자열로 조합
  const dateTimeString = `${year}-${month}-${day}T${hour}:${minute}:${second}+09:00`;

  return dateTimeString;
}

/**
 * date string의 타임존만 UTC(.000Z)에서 KST(+09:00)로 변경하여 반환하는 함수
 *
 * 서버에서 오는 날짜 형식은 한국 시간이지만 UTC 타임존을 가지고 있음.
 * 따라서 UTC가 아니라 KST이어야 함. (UTC라고 적혀있지만 UTC 기준 시간이 아닌 한국 시간 기준)
 *
 * @param string - date - 변환할 날짜와 시간이 담긴 날짜 문자열 (보통 서버에서 오는 데이터)
 *
 * @returns string - "2024-03-17T19:00:00+09:00" 형식으로 조정된 날짜와 시간 문자열
 */
function ignoreTimezone(date: string) {
  if (!isValid(new Date(date))) return "Invalid date";

  return date.replace(".000Z", "+09:00");
}

export {
  localToday,
  koreanToday,
  getKoreanTime,
  formatToKSTDateString,
  ignoreTimezone,
};
