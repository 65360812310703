import Image from "next/image";

import styled from "styled-components";

import { getOpacityColor, grayScale } from "@enkor/design/colors";
import { Typo } from "@enkor/design/components";
import { ellipsis, mediaQueries } from "@enkor/design/mixins";

export const MAX_CARD_WIDTH = 316;
export const AVATAR_SIZE = { WEB: 24, MOBILE: 20 };
const GAP_BETWEEN = 24;

export const EventCard = styled.div`
  position: relative;
  display: block;
  overflow: hidden;

  width: calc(100% - ${GAP_BETWEEN}px);
  min-width: 240px;
  height: auto;
  border: none;
  border-radius: 24px;

  background-color: ${getOpacityColor(grayScale[300], 0.7)};

  cursor: pointer;

  aspect-ratio: 48 / 61;

  ${mediaQueries.laptop} {
    width: 240px;
  }

  ${mediaQueries.mobile} {
    width: 213px;
  }
`;

export const EventInfoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  padding: 16px;

  transform: translate(-50%, -50%);
`;

export const BlurOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.6) 15.92%,
    rgba(0, 0, 0, 0) 45.5%
  );

  pointer-events: none;
`;

export const EventBackgroundImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

export const EventInfo = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const EventTitle = styled(Typo.Text)`
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;

  ${mediaQueries.laptop} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const HostInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const AvatarImageWrapper = styled.div<{ $withBorder?: boolean }>`
  position: relative;
  flex-shrink: 0;
  overflow: hidden;

  width: ${AVATAR_SIZE.WEB}px;
  height: ${AVATAR_SIZE.WEB}px;
  border: ${({ $withBorder }) =>
    $withBorder ? `1px solid ${grayScale.white}` : "none"};
  border-radius: 50%;

  ${mediaQueries.laptop} {
    width: ${AVATAR_SIZE.MOBILE}px;
    height: ${AVATAR_SIZE.MOBILE}px;
  }
`;

export const HostAvatar = styled(Image)`
  object-fit: cover;

  width: 100%;
  height: 100%;
`;

export const HostName = styled(Typo.Text)`
  ${ellipsis(1)}

  ${mediaQueries.laptop} {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
`;

export const EventDetailWrapper = styled.div`
  width: 100%;
`;

export const EventDetailBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  width: fit-content;
  margin-bottom: 4px;
  padding: 4px 8px;
  border-radius: 8px;

  background-color: ${getOpacityColor(grayScale.black, 0.2)};
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
`;

export const EventDetail = styled(Typo.Text)``;

export const LabelText = styled(Typo.Text)``;

export const AdditionalInfoWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
