import styled from "styled-components";

import { getOpacityColor, grayScale } from "@enkor/design/colors";
import { mediaQueries } from "@enkor/design/mixins";

const MAX_CARD_WIDTH = 324;

export const Container = styled.div`
  overflow: hidden;

  width: 100%;
`;

export const EventCardListWrapper = styled.div`
  display: flex;
  gap: 16px;

  margin: 0 16px;
`;

export const EventCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  max-width: ${MAX_CARD_WIDTH}px;
  height: auto;
  padding: 24px;
  border: 1px solid ${grayScale.main};
  border-radius: 24px;
  aspect-ratio: 8 / 10;

  background-color: ${getOpacityColor(grayScale[300], 0.7)};

  ${mediaQueries.laptop} {
    width: 242px;
    height: 323px;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ContentSkeleton = styled.div`
  border-radius: 2px;

  background-color: ${getOpacityColor(grayScale.white, 0.05)};
`;

export const LabelSkeleton = styled(ContentSkeleton)`
  width: 80px;
  height: 54px;
  border-radius: 8px;
`;

export const LongContent = styled(ContentSkeleton)`
  width: 210px;
  height: 72px;
`;

export const ShortContent = styled(ContentSkeleton)`
  width: 128px;
  height: 24px;
`;
